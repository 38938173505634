import { config } from '@/config';
import { ChainId } from '@monax/xylem/dist/types';
import { Chain as WagmiChain, goerli, mainnet, polygon, polygonMumbai } from '@wagmi/chains';

const WagmiChainIdMap: { [key in ChainId]?: WagmiChain } = {
  1: mainnet,
  5: goerli,
  137: polygon,
  80001: polygonMumbai,
  11297108109: {
    id: 11297108109,
    name: 'Palm',
    network: 'Palm',
    nativeCurrency: {
      name: 'Palm Token',
      symbol: 'PALM',
      decimals: 18,
    },
    rpcUrls: {
      public: { http: [config.contracts.nodeUrls[11297108109] || ''] },
      default: { http: [config.contracts.nodeUrls[11297108109] || ''] },
    },
  },
  11297108099: {
    id: 11297108099,
    name: 'Palm Testnet',
    network: 'Palm Testnet',
    nativeCurrency: {
      name: 'Palm Token',
      symbol: 'PALM',
      decimals: 18,
    },
    rpcUrls: {
      public: { http: [config.contracts.nodeUrls[11297108099] || ''] },
      default: { http: [config.contracts.nodeUrls[11297108099] || ''] },
    },
  },
  7700: {
    id: 7700,
    name: 'Canto',
    network: 'Canto',
    nativeCurrency: {
      name: 'Canto Utility Token',
      symbol: 'Canto',
      decimals: 18,
    },
    rpcUrls: {
      public: { http: [config.contracts.nodeUrls[7700] || ''] },
      default: { http: [config.contracts.nodeUrls[7700] || ''] },
    },
  },
  8453: {
    id: 8453,
    name: 'Base',
    network: 'Base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: {
      public: { http: [config.contracts.nodeUrls[8453] || ''] },
      default: { http: [config.contracts.nodeUrls[8453] || ''] },
    },
  },
  84531: {
    id: 8453,
    name: 'Base',
    network: 'Base',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: {
      public: { http: [config.contracts.nodeUrls[84531] || ''] },
      default: { http: [config.contracts.nodeUrls[84531] || ''] },
    },
  },
};

export const getWagmiChainById = (chainId: ChainId): WagmiChain => {
  const chain = WagmiChainIdMap[chainId];
  if (!chain) throw new Error(`getWagmiChainById: unhandled chain id: ${chainId}`);
  return chain;
};

export const wagmiChains = config.contracts.supportedChainIds.map(getWagmiChainById);
